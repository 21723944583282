body {
  margin: 0;
  background-color: #f5f5f5 !important;
}

.App {
  height: 100vh;
}

html {
  overflow-x: hidden;
  scrollbar-width: none;
}
